import React, { useState, useEffect } from "react";
import { Card, Tab, Table, Nav, Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { getAuditBuildingService } from "../../../services/audit.service";
import Spinner from "react-bootstrap/Spinner";
import WarningModal from "../../../components/modals/WarningModal";


const AddContent = (props) => {
  const { history } = props;
  const [loader, setLoader] = useState(false);
  
  const [buildings, setBuilding] = useState([]);
  // const cityId = localStorage.getItem("cityId");
  useEffect(() => {
    setLoader(true)
    getAuditBuildingService()
      .then((response) => {
        setBuilding(response.data);
        setLoader(false)
      })
      .catch((err) => {
        console.log("res", err);
        setLoader(false)
      });
  }, []);

  return (
    <>
    <WarningModal
      // showModal={showModal}
      // setShowModal={setShowModal}
      // deleteHandler={deleteCityHandler}
    />
    {/* {state.activeTab === currentTab ? ( */}
    <div className="custom-tab-1 city_view_container">
      <Tab.Container>
        <Tab.Content>
          <Card>
            <Card.Header className="d-flex justify-content-between align-items-center">
              <Card.Title>Audit Page</Card.Title>
              {/* <Card.Title>
                <Button
                  className="btn btn-primary shadow btn m-0 sharp mr-1"
                  // onClick={() => {
                  //   history.push("/add-city");
                  // }}
                >
                  Text
                </Button>
              </Card.Title> */}
            </Card.Header>
            <Card.Body>
              {/* section1 table	*/}
              {!loader ? (
                <div>
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>
                          <strong>Formated Address</strong>
                        </th>
                        {/* <th>
                          <strong>URL</strong>
                        </th> */}
                        <th>
                          <strong>View</strong>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="table_body">
                      {
                      buildings?.map((building, index) => (
                        <tr>
                          <td>{building?.formatted_address}</td>
                          {/* <td>
                            <a href={`${building?.userdataurl}`} target="_blank" >
                            {building?.userdataurl }
                            </a>
                          </td> */}
                          {/* <td>{city?.copied_from_city}</td> */}
                          <td className="d-flex btns_container">
                            
                            <Button
                              onClick={() => {
                                history.push(`/view-audit-users/${building?.id}?${building?.formatted_address.replace(/ /g, '-')}?${building.userdataurl+'&'+building.GRB_OIDN_}`);
                              }}
                             
                            >
                             <i className="fa fa-eye"></i>
                            </Button>
                          </td>
                        </tr>
                      ))
                      }
                    </tbody>
                  </Table>
                </div>
              ) : (
                <div className="row  py-5 d-flex justify-content-center">
                  <Spinner animation="border" className="m-auto" />
                </div>
              )}
            </Card.Body>
          </Card>
        </Tab.Content>
      </Tab.Container>
    </div>
   
  </>
  );
};
export default withRouter(AddContent);
