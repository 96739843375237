import React from "react";
import { Table, Button } from "react-bootstrap";

const CustomTable = ({ onEdit, data, headers, detailsTable }) => {
	return (
		<Table responsive>
			<thead>
				<tr>
					{headers.map((title) => (
						<th key={title}>
							<strong>{title}</strong>
						</th>
					))}
				</tr>
			</thead>
			<tbody>
				{detailsTable
					? detailsTable.map((row, index) =>
							row.key == "tab" || row.key == "folder" ? (
								<>
									<tr key={row.id}>
										<th scope="row">{index + 1}</th>
										<td>{row.key}</td>
										{row.key == "image" ? (
											<img src={process.env.REACT_APP_BACKEND_ASSETS_URL + row.value} className="rounded-lg mr-2" width="100" alt="" />
										) : (
											<td dangerouslySetInnerHTML={{ __html: row.value }}></td>
										)}
										<td>
											<Button className="btn btn-primary shadow btn-xs sharp mr-1" onClick={() => onEdit(row)}>
												<i className="fa fa-pencil"></i>
											</Button>
										</td>
									</tr>

									{detailsTable?.map(
										(data, i) =>
											row.id == data.group_id && (
												<tr>
													<th scope="row" style={{ borderTop: "none" }}></th>
													<td style={{ borderTop: "none" }}>{data.key}</td>

													{data.key == "image" ? (
														<td>
															<img src={process.env.REACT_APP_BACKEND_ASSETS_URL + data.value} className="rounded-lg ml-2" width="100" alt="" />
														</td>
													) : data.key == "pdf" || data.key == "doc" ? (
														<td>
															<a href={process.env.REACT_APP_BACKEND_ASSETS_URL + data.value} target="_blank">
																{data.value}
															</a>
														</td>
													) : (
														<td dangerouslySetInnerHTML={{ __html: data.value }} style={{ borderTop: "none" }}></td>
													)}
													<td style={{ borderTop: "none" }}>
														<Button className="btn btn-primary shadow btn-xs sharp mr-1" onClick={() => onEdit(data)}>
															<i className="fa fa-pencil"></i>
														</Button>
													</td>
												</tr>
											)
									)}
								</>
							) : (
								<>
									{row.group_id === 0 && (
										<tr key={row.id}>
											<th scope="row">{index + 1}</th>
											<td>{row.key}</td>
											{row.key == "image" ? (
												<td>
													<img src={process.env.REACT_APP_BACKEND_ASSETS_URL + row.value} className="rounded-lg mr-2" width="100" alt="" />
												</td>
											) : (
												<td dangerouslySetInnerHTML={{ __html: row.value }}></td>
											)}
											<td>
												<Button className="btn btn-primary shadow btn-xs sharp mr-1" onClick={() => onEdit(row)}>
													<i className="fa fa-pencil"></i>
												</Button>
											</td>
										</tr>
									)}
								</>
							)
					  )
					: data.map((row, index) =>
							row.tab ? (
								<tr key={row.id}>
									<th scope="row">{index + 1}</th>
									<td>{row.title}</td>
									{row.decription && <td dangerouslySetInnerHTML={{ __html: row.decription }}></td>}

									<td>
										<img src={process.env.REACT_APP_BACKEND_ASSETS_URL + row.image_url} className="rounded-lg mr-2" width="100" alt="" />
									</td>
									<td>
										<Button className="btn btn-primary shadow btn-xs sharp mr-1" onClick={() => onEdit(row)}>
											<i className="fa fa-pencil"></i>
										</Button>
									</td>
								</tr>
							) : (
								<tr key={row.id}>
									<th scope="row">{index + 1}</th>
									<td>{row.name}</td>
									<td dangerouslySetInnerHTML={{ __html: row.link }}></td>
									<td>
										<img src={process.env.REACT_APP_BACKEND_ASSETS_URL + row.image_url} className="rounded-lg mr-2" width="100" alt="" />
									</td>
									<td>
										<Button className="btn btn-primary shadow btn-xs sharp mr-1" onClick={() => onEdit(row)}>
											<i className="fa fa-pencil"></i>
										</Button>
									</td>
								</tr>
							)
					  )}
			</tbody>
		</Table>
	);
};
export default CustomTable;
