import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Card, Tab, Table, Nav, Button } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";

const ViewHomeContent = (props) => {
	let { history } = props;
	const [currentTab, setCurrentTab] = useState(false);
	const [state, setState] = useState({
		activeTab: "",
	});
	
	return (
		<>
			{/* {state.activeTab === currentTab ? ( */}
			<div className="custom-tab-1">
				<Tab.Container defaultActiveKey={state.activeTab}>
					<Tab.Content activeTab={state.activeTab}>
						<Card>
							<Card.Header>
								<Card.Title>Home Page</Card.Title>
							</Card.Header>
							<Card.Body>
								{/*  top right section 	*/}
								<div>
									<h4 className="py-2">Section 1 Top Right</h4>
									<Table responsive>
										<thead>
											<tr>
												<th>
													<strong>#id</strong>
												</th>
												<th>
													<strong>Title</strong>
												</th>
												<th>
													<strong>Sub Title</strong>
												</th>
												<th className="text-right">
													<strong>Action</strong>
												</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<th scope="row">1</th>
												<td>56 </td>
												<td>100% vegetal-organic 4 in 1 solution</td>
												<td className="text-right btns_container">
													<Button
														className="btn btn-primary shadow btn sharp mr-1"
														onClick={() => {
															history.push("/edit-section-one");
														}}
													>
														<i className="fa fa-pencil"></i>
													</Button>
													<Button className="btn btn-danger shadow btn sharp mr-1">
														<i className="fa fa-trash-o"></i>
													</Button>
												</td>
											</tr>
										</tbody>
									</Table>
								</div>
								{/*  section 1 main Title	*/}
								<div>
									<h4 className="py-2">Section 1 Heading</h4>
									<Table responsive>
										<thead>
											<tr>
												<th>
													<strong>#id</strong>
												</th>
												<th>
													<strong>Title</strong>
												</th>
												<th className="text-right">
													<strong>Action</strong>
												</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<th scope="row">1</th>
												<td>Renovate together </td>
												<td className="text-right btns_container">
													<Button
														className="btn btn-primary shadow btn sharp mr-1"
														onClick={() => {
															history.push("/edit-section-one");
														}}
													>
														<i className="fa fa-pencil"></i>
													</Button>
													<Button className="btn btn-danger shadow btn sharp mr-1">
														<i className="fa fa-trash-o"></i>
													</Button>
												</td>
											</tr>
										</tbody>
									</Table>
								</div>
								{/*  section 1 main Title	*/}
								<div>
									<h4 className="py-2">Section 1 Form</h4>
									<Table responsive>
										<thead>
											<tr>
												<th>
													<strong>#id</strong>
												</th>
												<th>
													<strong>label</strong>
												</th>
												<th>
													<strong>Input value</strong>
												</th>
												<th>
													<strong>Image</strong>
												</th>
												<th>
													<strong>link</strong>
												</th>
												<th className="text-right">
													<strong>Action</strong>
												</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<th scope="row">1</th>
												<td>label</td>
												<td>Renovate together </td>
												<td>input Value </td>
												<td>link </td>
												<td className="text-right btns_container">
													<Button
														className="btn btn-primary shadow btn sharp mr-1"
														onClick={() => {
															history.push("/edit-section-one");
														}}
													>
														<i className="fa fa-pencil"></i>
													</Button>
													<Button className="btn btn-danger shadow btn sharp mr-1">
														<i className="fa fa-trash-o"></i>
													</Button>
												</td>
											</tr>
										</tbody>
									</Table>
								</div>
								{/* section 1 bottom content */}
								<div className="px-1">
									<h4 className="py-2">Benefits</h4>
									<Nav as="ul" className="nav-tabs my-2">
										<Nav.Item as="li" className="d-flex">
											<Nav.Link
											// onClick={() => }}
											// eventKey={tab.title}
											>
												Your Benefits
											</Nav.Link>
											<Nav.Link>Benefits Aalst</Nav.Link>
										</Nav.Item>
									</Nav>
									<Table responsive className="my-3">
										<thead>
											<tr>
												<th>
													<strong>#id</strong>
												</th>
												<th>
													<strong>Image</strong>
												</th>
												<th>
													<strong>Title</strong>
												</th>
												<th>
													<strong>Price</strong>
												</th>
												<th className="text-right">
													<strong>Action</strong>
												</th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<th scope="row">1</th>
												<td>Image</td>
												<td>solution</td>
												<td>50</td>
												<td className="text-right btns_container">
													<Button
														className="btn btn-primary shadow btn sharp mr-1"
														onClick={() => {
															history.push("/edit-home-content");
														}}
													>
														<i className="fa fa-pencil"></i>
													</Button>
													<Button className="btn btn-danger shadow btn sharp mr-1">
														<i className="fa fa-trash-o"></i>
													</Button>
												</td>
											</tr>
										</tbody>
									</Table>
								</div>
							</Card.Body>
						</Card>
					</Tab.Content>
				</Tab.Container>
			</div>
			{/* <div className="row">
				<Spinner animation="border" className="m-auto" />
			</div> */}
		</>
	);
};
export default withRouter(ViewHomeContent);
