import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Card, Tab, Table, Nav, Button } from "react-bootstrap";
import { CityService, deleteCity } from "../../../services/city";
import Spinner from "react-bootstrap/Spinner";
import WarningModal from "../../../components/modals/WarningModal";
import {
  GetAllFaqsByService,
  DeleteSingleFaqByService,
} from "../../../services/faqs";
// import { Markup } from 'interweave';
const ReactQuill = typeof window === 'object' ? require('react-quill') : () => false;


const ViewCity = (props) => {
  let { history } = props;
  const cityId = localStorage.getItem("cityId");
  const [removeCity, setRemoveCity] = useState(0);
  const [loader, setLoader] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [getCity, setGetCity] = useState([]);
  useEffect(() => {
    setLoader(true);
    GetAllFaqsByService(cityId)
      .then((response) => {
        setGetCity(response?.data);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
  }, []);

  const deleteCityHandler = () => {
    DeleteSingleFaqByService(removeCity)
      .then((response) => {
        let record = getCity.filter((single) => single?.id !== removeCity);
        setShowModal(false);
        setGetCity(record);
      })
      .catch((error) => {
        console.log("res", error);
      });
  };

  const getCategoryName = (value) => {
    switch (value) {
      case 1:
        return "Neighborhood";
      case 2:
        return "Support for subsidies & practicalities";
      case 3:
        return "Renovations";
      case 4:
        return "How it works";
      default:
        return "-";
    }
  };

  return (
    <>
      <WarningModal
        showModal={showModal}
        setShowModal={setShowModal}
        deleteHandler={deleteCityHandler}
      />
      {/* {state.activeTab === currentTab ? ( */}
      <div className="custom-tab-1 city_view_container">
        <Tab.Container>
          <Tab.Content>
            <Card>
              <Card.Header className="d-flex justify-content-between align-items-center">
                <Card.Title>FAQ</Card.Title>
                <Card.Title>
                  <Button
                    className="btn btn-primary shadow btn m-0 sharp mr-1"
                    onClick={() => {
                      history.push("/add-faq");
                    }}
                  >
                    Add FAQ
                  </Button>
                </Card.Title>
              </Card.Header>
              <Card.Body>
                {/* section1 table	*/}
                {!loader ? (
                  <div>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>
                            <strong>Title</strong>
                          </th>
                          <th>
                            <strong>Description</strong>
                          </th>
                          <th>
                            <strong>Category</strong>
                          </th>
                          <th>
                            <strong>Action</strong>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="table_body">
                        {getCity?.map((city, index) => (
                          <tr key={index}>
                            <td>{city?.title}</td>
                            <td> {city?.description && <ReactQuill 
                                theme={'bubble'}
                                readOnly={true}
                                value={city?.description }
                                
                                 />}
                   {/* <Markup content={city?.description} /> */}
                   </td>
                            <td className="">
                              {getCategoryName(parseInt(city?.category))}
                            </td>
                            {/* <td>{city?.copied_from_city}</td> */}
                            <td className="d-flex btns_container">
                              <Button
                                className="btn btn-primary shadow btn sharp mr-1"
                                onClick={() => {
                                  history.push(`/edit-faqs/${city?.id}`);
                                }}
                              >
                                <i className="fa fa-pencil"></i>
                              </Button>
                              <Button
                                className="btn btn-danger shadow btn sharp mr-1"
                                onClick={() => {
                                  setShowModal(true);
                                  setRemoveCity(city?.id);
                                }}
                              >
                                <i className="fa fa-trash-o"></i>
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                ) : (
                  <div className="row  py-5 d-flex justify-content-center">
                    <Spinner animation="border" className="m-auto" />
                  </div>
                )}
              </Card.Body>
            </Card>
          </Tab.Content>
        </Tab.Container>
      </div>
      {/* <div className="row">
				<Spinner animation="border" className="m-auto" />
			</div> */}
    </>
  );
};
export default withRouter(ViewCity);
