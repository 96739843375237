import { useState, useEffect } from "react";
import { withRouter } from "react-router";
import { Card } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { UpdateMenuByService, GetSingleMenuByService } from "../../../../services/menus";

const EditContent = (props) => {
	const { match, history } = props;
	const id = match?.params?.id;
	console.log("id",match)
	const [menu, setMenu] = useState({});
	useEffect(() => {
		let city_id = { id: id };
		GetSingleMenuByService(city_id)
			.then((response) => {
				localStorage.setItem("menuTitle","menu-updated");
				setMenu(response?.data?.data);
			})
			.catch((error) => {
				console.log("res", error);
			});
	}, []);

	const editCityHandler = (e) => {
		e.preventDefault();
		UpdateMenuByService(menu)
			.then((response) => {
				history.push(`/menus`);
			})
			.catch((error) => {
				console.log("response", error);
			});
	};

	return (
		<Card className="p-3">
			<form
				className="py-3"
				onSubmit={(e) => {
					editCityHandler(e);
				}}
			>
				<div className="form-group m-0 py-3">
					<label>Title</label>
					<input
						type="text"
						className="form-control "
						placeholder=""
						onChange={(e) => {
							let duplicate = { ...menu };
							duplicate.title = e.target.value;
							setMenu(duplicate);
						}}
						value={menu?.title}
					/>
				</div>
				<div className="d-flex justify-content-end py-3">
					<Button className="btn btn-primary shadow sharp px-4" type="submit">
						Submit
					</Button>
				</div>
			</form>
		</Card>
	);
};
export default withRouter(EditContent);
