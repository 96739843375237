import { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { GetSingleFAQByService, UpdateFAQByService } from "../../../../services/faqs";
import { withRouter } from "react-router-dom";

const EditContent = (props) => {
	const { match, history } = props;
	const id = match?.params?.id;
	const [title, setTitle] = useState("");
	const [value, setValue] = useState("");
	useEffect(() => {
		let city_id = { id: id };
		GetSingleFAQByService(city_id)
			.then((response) => {
				setTitle(response?.data?.data);
				console.log(response?.data?.data?.page_contents[0]?.value);
				setValue(response?.data?.data?.page_contents[0]);
			})
			.catch((error) => {
				console.log("res", error);
			});
	}, []);

	const editFAQHandler = (e) => {
		e.preventDefault();
		const payload = {
			title: title.title,
			value: value.value,
			id: id,
		};
		UpdateFAQByService(payload)
			.then((response) => {
				history.push("/faq");
			})
			.catch((error) => {
				console.log(error);
			});
	};
	return (
		<Card className="p-3">
			<form className="py-3" onSubmit={(e) => editFAQHandler(e)}>
				<div className="form-group m-0 py-3">
					<label>Title</label>
					<input
						type="text"
						className="form-control "
						placeholder=""
						onChange={(e) => {
							let duplicate = { ...title };
							duplicate.title = e.target.value;
							setTitle(duplicate);
						}}
						value={title?.title}
					/>
				</div>
				<div className="form-group m-0 py-3">
					<label>Answer</label>
					<textarea
						type="text"
						className="form-control py-2"
						placeholder=""
						onChange={(e) => {
							let duplicate = { ...value };
							duplicate.value = e.target.value;
							setValue(duplicate);
						}}
						value={value?.value}
					/>
				</div>
				<div className="d-flex justify-content-end py-3">
					<Button className="btn btn-primary shadow sharp px-4" type="submit">
						Submit
					</Button>
				</div>
			</form>
		</Card>
	);
};
export default withRouter(EditContent);
