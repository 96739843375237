import { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Card } from "react-bootstrap";
import { Button, Spinner } from "react-bootstrap";
import {
  UpdateOverHeadPageService,
  GetAllOverHeadService,
} from "../../../services/testimonial";
const ReactQuill =
  typeof window === "object" ? require("react-quill") : () => false;

const EditContent = (props) => {
  const { match, history } = props;
  const id = match?.params?.id;
  const [loader, setLoader] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    address: "",
    image: {},
    cityId: localStorage.getItem("cityId"),
    description: "",
  });

  const {
    name,
    address,
    image,
    // cityId,
  } = formData;

  const [description, setDescription] = useState();
  const [overheadPage, setOverheadPage] = useState([]);
  //   APi call to get data
  const getContentHandler = () => {
    setLoader2(true);
    const language = history?.location?.search?.split("=")[1] === "fr" ? 2 : 1;
    GetAllOverHeadService(id, language)
      .then((response) => {
        setOverheadPage(response.data?.data);
        setDescription(response.data?.data?.description);
        setLoader2(false);
      })
      .catch((err) => {
        setLoader2(false);
      });
  };

  useEffect(() => {
    getContentHandler();
  }, []);

  const updateTestimonial = (e) => {
    e.preventDefault();
    setLoader(true);
    const language = history?.location?.search?.split("=")[1] === "fr" ? 2 : 1;
    // payload
    const payload = {
      id: overheadPage?.id,
      city_id: Number(id),
      language_id: language,
      title: overheadPage?.title,
      description: description,
      desc_1: overheadPage?.desc_1,
      desc_2: overheadPage?.desc_2,
    };
    // API call
    UpdateOverHeadPageService(payload, overheadPage?.id)
      .then((response) => {
        setLoader(false);
        history.push(`/overhead/${id}`);
      })
      .catch((error) => {
        setLoader(false);
        console.log("response", error);
      });
  };

  const onChangeHandler = (key, value) => {
    let duplicate = { ...overheadPage };
    duplicate[key] = value;
    setOverheadPage(duplicate);
  };

  return (
    <Card className="p-3">
      {loader2 ? (
        <div className="d-flex justify-content-center align-items-center h-100">
          <Spinner animation="border" role="status">
            <span className="visually-hidden"></span>
          </Spinner>
        </div>
      ) : (
        <form
          className="py-3"
          onSubmit={(e) => {
            updateTestimonial(e);
          }}
        >
          <div className="form-group m-0 py-3">
            <label>Title</label>
            <input
              type="text"
              className="form-control"
              placeholder=""
              name="name"
              value={overheadPage?.title}
              onChange={(e) => onChangeHandler("title", e.target.value)}
              required
            />
          </div>
          <div className="form-group m-0 py-3">
            <label>Logo 1 Name</label>
            <textarea
              type="text"
              className="form-control py-2"
              placeholder=""
              name="address"
              value={overheadPage?.desc_1}
              onChange={(e) => onChangeHandler("desc_1", e.target.value)}
              required
            />
          </div>
          <div className="form-group m-0 py-3">
            <label>Logo 2 Name</label>
            <textarea
              type="text"
              className="form-control py-2"
              placeholder=""
              name="address"
              value={overheadPage?.desc_2}
              onChange={(e) => onChangeHandler("desc_2", e.target.value)}
              required
            />
          </div>
          <div className="form-group m-0 py-3">
            <label>Description</label>
            <div className="react_quill_container">
              <ReactQuill
                theme="snow"
                value={description}
                onChange={(value) => {
                  setDescription(value);
                  // onChangeHandler("description", value);
                }}
              />
            </div>
            {/* <textarea
						type="text"
						className="form-control"
						placeholder=""
						name="description"
						value={description}
						onChange={(e) => onChangeFormData(e)}
						required
					/> */}
          </div>
          <div className="d-flex justify-content-end py-3">
            <Button
              className="btn btn-primary shadow sharp px-4"
              type="submit"
              disabled={loader ? true : false}
            >
              Submit
            </Button>
          </div>
        </form>
      )}
    </Card>
  );
};
export default withRouter(EditContent);
