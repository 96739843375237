import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { Card, Tab, Table, Nav, Button } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";

const ViewHomeContent = (props) => {
	let { history } = props;
	const [currentTab, setCurrentTab] = useState(false);
	const [state, setState] = useState({
		activeTab: "",
	});

	return (
		<>
			{/* {state.activeTab === currentTab ? ( */}
			<div className="custom-tab-1">
				<Tab.Container defaultActiveKey={state.activeTab}>
					<Tab.Content activeTab={state.activeTab}>
						<Card>
							<Card.Header>
								<Card.Title>Profile Page</Card.Title>
							</Card.Header>
							<Card.Body>
								{/* section1 table	*/}
								<Table responsive>
									<thead>
										<tr>
											<th>
												<strong>#id</strong>
											</th>
											<th>
												<strong>Title</strong>
											</th>
											<th>
												<strong>Description</strong>
											</th>
											<th>
												<strong>Action</strong>
											</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<th scope="row">1</th>
											<td>2055G </td>
											<td>100% vegetal-organic 4 in 1 solution</td>
											<td>
												<Button
													className="btn btn-primary shadow btn-xs sharp mr-1"
													onClick={() => {
														history.push("/edit-profile");
													}}
												>
													<i className="fa fa-pencil"></i>
												</Button>
												<Button className="btn btn-danger shadow btn-xs sharp mr-1">
													<i className="fa fa-trash-o"></i>
												</Button>
											</td>
										</tr>
									</tbody>
								</Table>
								{/* lists of section 1 */}
								<Nav as="ul" className="nav-tabs my-2">
									<Nav.Item as="li" className="d-flex">
										<Nav.Link
										// onClick={() => }}
										// eventKey={tab.title}
										>
											Item 1
										</Nav.Link>
										<Nav.Link>Item 2</Nav.Link>
									</Nav.Item>
								</Nav>
								<Table responsive className="my-3">
									<thead>
										<tr>
											<th>
												<strong>#id</strong>
											</th>
											<th>
												<strong>Title</strong>
											</th>
											<th>
												<strong>Description</strong>
											</th>
											<th>
												<strong>Action</strong>
											</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<th scope="row">1</th>
											<td>2055G </td>
											<td>100% vegetal-organic 4 in 1 solution</td>
											<td>
												<Button
													className="btn btn-primary shadow btn-xs sharp mr-1"
													onClick={() => {
														history.push("/edit-home-content");
													}}
												>
													<i className="fa fa-pencil"></i>
												</Button>
												<Button className="btn btn-danger shadow btn-xs sharp mr-1">
													<i className="fa fa-trash-o"></i>
												</Button>
											</td>
										</tr>
									</tbody>
								</Table>
							</Card.Body>
						</Card>
					</Tab.Content>
				</Tab.Container>
			</div>
			{/* <div className="row">
				<Spinner animation="border" className="m-auto" />
			</div> */}
		</>
	);
};
export default withRouter(ViewHomeContent);
