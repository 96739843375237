import React, { useCallback, useEffect, useState } from "react";
import { Card, Tab, Nav, Modal, Button } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Table from "../../../components/customTable/table";
import Spinner from "react-bootstrap/Spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const tableHeader = ["#", "Title", "Video", "Action"];

const Videos = () => {
  const [state, setState] = useState({
    basicModal: false,
    tabData: [],
    data: [],
  });

  const getCall = () => {
    axios
      .get(process.env.REACT_APP_BACKEND_URL + `video/`)

      .then((response) => {
        const data = response.data.data;
        setState((st) => ({
          ...st,
          data: data,
        }));
      });
  };
  useEffect(() => {
    getCall();
  }, []);
  // Schema for yup
  const validationSchema = Yup.object().shape({
    title: Yup.string().required("* Title is required"),
  });
  const setRecord = useCallback((record) => {
    setState((st) => ({ ...st, record, basicModal: true }));
  }, []);
  // Notification
  const notify = (status, text) => {
    if (status == "success") {
      toast.success(`✅ ${text}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else if (status == "error") {
      toast.error(`❌ ${text} !`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
    }
  };
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Modal className="fade" show={state.basicModal}>
        <Formik
          initialValues={{
            title: state?.record?.title || "",
            video: state?.record?.video || "",
          }}
          onSubmit={(values) => {
            let formData = new FormData();
            formData.append("title", values.title);
            formData.append("video", values.video);

            axios
              .put(
                process.env.REACT_APP_BACKEND_URL +
                  "support/updateVideo/" +
                  state?.record?.id,
                formData
              )
              .then((response) => {
                if (response.data.success === true) {
                  setState((st) => ({ ...st, basicModal: false }));
                  getCall();
                  notify("success", "Record Updated");
                }
              })
              .catch((error) => {
                error.response.statusText == ""
                  ? notify("error", error.response.data.data.message)
                  : notify("error", error.response.statusText);
              });
          }}
          // Hooks up our validationSchema to Formik
          validationSchema={validationSchema}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            } = props;
            return (
              <>
                <form action="" onSubmit={handleSubmit}>
                  <Modal.Header>
                    <Modal.Title>Edit Content</Modal.Title>
                    <Button
                      variant=""
                      className="close"
                      onClick={() =>
                        setState((st) => ({ ...st, basicModal: false }))
                      }
                    >
                      <span>&times;</span>
                    </Button>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="form-row">
                      <div className="form-group col-md-12">
                        <label>Title</label>
                        <input
                          type="text"
                          className="form-control"
                          name="title"
                          placeholder="Type here"
                          value={values.title}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="input-group col-md-12">
                        <div className="custom-file">
                          <input
                            name="video"
                            type="file"
                            className="custom-file-input"
                            onChange={handleChange}
                          />
                          <label className="custom-file-label">
                            Choose file
                          </label>
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      onClick={() =>
                        setState((st) => ({ ...st, basicModal: false }))
                      }
                      variant="danger light btn-sm"
                    >
                      Close
                    </Button>
                    <input
                      type="submit"
                      value="Save changes"
                      className="btn btn-primary btn-sm"
                    />
                  </Modal.Footer>
                </form>
              </>
            );
          }}
        </Formik>
      </Modal>
      {state?.data.length > 0 ? (
        <Card>
          {/* <Card.Header>
          <Card.Title>{state.activeTab} Content</Card.Title>
        </Card.Header> */}
          <Card.Body>
            <div className="custom-tab-1">
              <Table
                data={state?.data}
                headers={tableHeader}
                onEdit={setRecord}
                pageName="videos"
              />
            </div>
          </Card.Body>
        </Card>
      ) : (
        <div className="row">
          <Spinner animation="border" className="m-auto" />
        </div>
      )}
    </>
  );
};
export default Videos;
