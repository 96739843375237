import React from "react";
import { Table, Button } from "react-bootstrap";
import ReactPlayer from "react-player";

const ContactTable = ({ onEdit, data, headers, pageName }) => {
  return (
    <Table responsive>
      <thead>
        <tr>
          {headers.map((title) => (
            <th key={title}>
              <strong>{title}</strong>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {pageName == "contact" &&
          data?.map((row, index) => (
            <tr key={row.id}>
              <th scope="row">{index + 1}</th>
              <td>{row.title}</td>
              <td>{row.name}</td>
              <td>{row.rank}</td>
              <td>{row.email}</td>
              <td>{row.phone}</td>
              <td>{row.address}</td>
              <td>{row.type}</td>
              <td>
                <Button
                  className="btn btn-primary shadow btn-xs sharp mr-1"
                  onClick={() => onEdit(row)}
                >
                  <i className="fa fa-pencil"></i>
                </Button>
              </td>
            </tr>
          ))}
        {pageName == "videos" &&
          data?.map((row, index) => (
            <tr key={row.id}>
              <th scope="row">{index + 1}</th>
              <td>{row.title}</td>
              <td>
                <ReactPlayer
                  width="360px"
                  height="100%"
                  controls={true}
                  light={true}
                  url={process.env.REACT_APP_BACKEND_ASSETS_URL + row.video}
                  config={{
                    file: { attributes: { controlsList: "nodownload" } },
                  }}
                />
              </td>
              <td>
                <Button
                  className="btn btn-primary shadow btn-xs sharp mr-1"
                  onClick={() => onEdit(row)}
                >
                  <i className="fa fa-pencil"></i>
                </Button>
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
};
export default ContactTable;
