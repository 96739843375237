import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Card, Tab, Table, Nav, Button } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { PageContentByService } from "../../../../services/menus";
import { GetSinglePageContentByService, GetSingleMenuByService } from "../../../../services/menuContent";
import { Markup } from 'interweave';

const ViewHomeContent = (props) => {
	const { match, history } = props;
	const selectedMenuId = match?.params?.id;
	const [loader, setLoader] = useState(true);
	const [activeTab, setactiveTab] = useState();
	// const [menus, setMenus] = useState([]);
	const [menuTitles, setMenuTitles] = useState([]);
	const [menuContent, setMenuContent] = useState([]);
	const cityId = localStorage.getItem("cityId");

	useEffect(() => {
		GetSinglePageContentByService({ id: selectedMenuId })
			.then((response) => {
				setMenuTitles(response?.data?.data);
				setactiveTab(response?.data?.data[0]?.position);
				setLoader(false);
			})
			.catch((error) => {
				console.log("error", error);
				setLoader(false);
			});
		GetSingleMenuByService({ id: selectedMenuId })
			.then((response) => {
				setMenuContent(response?.data?.data?.page_contents);
			})
			.catch((error) => {
				console.log("error", error);
			});
	}, [selectedMenuId]);

	let filterData = [];
	if (menuContent?.length !== 0) {
		filterData = menuContent?.filter((obj) => obj.position === activeTab);
	}
	console.log("menuContent", menuContent);
	console.log("filterData", filterData);
	return (
		<>
			{!loader ? (
				<div className="custom-tab-1 menus-content-container">
					<Tab.Container defaultActiveKey={activeTab}>
						<Tab.Content>
							<Card>
								<Card.Header>
									<Card.Title>Result Page</Card.Title>
								</Card.Header>
								<Card.Body>
									{menuTitles?.length !== 0 && (
										<Nav as="ul" className="nav-tabs mb-4">
											<Nav.Item as="li" className="d-flex">
												{menuTitles?.map((menu, index) => (
													<Nav.Link
														key={index}
														onClick={() => {
															setactiveTab(menu?.position);
														}}
														eventKey={menu?.position}
													>
														{menu?.position}
													</Nav.Link>
												))}
											</Nav.Item>
										</Nav>
									)}
									<Tab.Content activeTab={activeTab} className="pt-4">
										<Table responsive className="my-3">
											<thead>
												<tr>
													<th>
														<strong>Key</strong>
													</th>
													<th>
														<strong>Value</strong>
													</th>
													<th className="text-right">
														<strong>Action</strong>
													</th>
												</tr>
											</thead>
											{filterData ? (
												<tbody>
													{filterData?.map((pageContent, index) => (
														<tr key={index}>
															<td>{pageContent?.key} </td>
															<td>
																<Markup content={pageContent?.value} />
																
																{/* {pageContent?.value} */}
																</td>
															<td className="text-right btns_container">
																<Button
																	className="btn btn-primary shadow btn sharp mr-1"
																	onClick={() => {
																		history.push(`/edit-result-menu-content/${pageContent?.id}`);
																	}}
																>
																	<i className="fa fa-pencil"></i>
																</Button>
															</td>
														</tr>
													))}
												</tbody>
											) : (
												<div className="no-record-container">
													<p className="text-center">No Record Found</p>
												</div>
											)}
										</Table>
									</Tab.Content>
								</Card.Body>
							</Card>
						</Tab.Content>
					</Tab.Container>
				</div>
			) : (
				<div className="row">
					<Spinner animation="border" className="m-auto" />
				</div>
			)}
		</>
	);
};
export default withRouter(ViewHomeContent);
