import { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { CreateFAQ } from "../../../services/faqs";
import { withRouter } from "react-router-dom";
const ReactQuill = typeof window === 'object' ? require('react-quill') : () => false;

const AddContent = (props) => {
  const { history } = props;
  const [loader, setLoader] = useState(false);
  const city_Id = localStorage.getItem("cityId");
  const [city, setCity] = useState({
    title: "",
    description: "",
    category: "",
    cityId: city_Id,
  });
  const cityId = localStorage.getItem("cityId");
  const addCityHandler = (e) => {
    e.preventDefault();
    setLoader(true);
    CreateFAQ(city)
      .then((response) => {
        setLoader(false);
        history.push(`/faqs/${cityId}`);
      })
      .catch((error) => {
        setLoader(false);
        console.log("response", error);
      });
  };
  return (
    <Card className="p-3">
      <form
        className="py-3"
        onSubmit={(e) => {
          addCityHandler(e);
        }}
      >
        <div className="form-group m-0 py-3">
          <label>Title</label>
          <input
            type="text"
            className="form-control "
            placeholder=""
            onChange={(e) => {
              let duplicate = { ...city };
              duplicate.title = e.target.value;
              setCity(duplicate);
            }}
            required
          />
        </div>
        <div className="form-group m-0 py-3">
          <label>Description</label>
          <div className="react_quill_container_faq">
          <ReactQuill 
             theme="snow"
                   onChange={(value) => {
                    let duplicate = { ...city };
              duplicate.description = value;
              setCity(duplicate);
                   }} />
            </div>
          {/* <textarea
            type="text"
            className="form-control pt-3 pb-0"
            placeholder=""
            onChange={(e) => {
              let duplicate = { ...city };
              duplicate.description = e.target.value;
              setCity(duplicate);
            }}
          /> */}
        </div>
        <div className="form-group m-0 py-3">
          <label>Category</label>
          <select
            type="text"
            className="form-control "
            placeholder=""
            onChange={(e) => {
              let duplicate = { ...city };
              duplicate.category = e.target.value;
              setCity(duplicate);
            }}
          >
            <option value="">Select Category</option>
            <option value="1">Neighborhood</option>
            <option value="2">Support for subsidies & practicalities</option>
            <option value="3">Renovations</option>
            <option value="4">How it works</option>
          </select>
        </div>
        <div className="d-flex justify-content-end py-3">
          <Button
            className="btn btn-primary shadow sharp px-4"
            type="submit"
            disabled={loader ? true : false}
          >
            Submit
          </Button>
        </div>
      </form>
    </Card>
  );
};
export default withRouter(AddContent);
