import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Formik } from "formik";
import { Table, Nav } from "react-bootstrap";


const ViewUserOffer = ({ userOffer, showModal, setShowModal }) => {
    const [baseOffer, setBaseOffer] = useState();
    // let total=0;
    useEffect(() => {
        if(userOffer?.base_offer){
        setBaseOffer(JSON.parse(userOffer.base_offer))
    }
    }, [userOffer]);

    /**
     * this function take indiviual renovation cost.
     * @param {*} renovation 
     * @returns indiviual renovation cost.
     */
    const renovationCost = (renovation) => {
        if(renovation.includes('wall')){            
           return parseInt(baseOffer[0].wall_investment_cost)
        }else if(renovation.includes('window')){            
            return parseInt(baseOffer[0].window_investment_cost)
        } else if(renovation.includes('vent')){
            return parseInt(baseOffer[0].ventilation_investment_cost)
        }else if(renovation.includes('roof')){
            return parseInt(baseOffer[0].roof_investment_cost)
        }else if(renovation.includes('heat') || renovation.includes('cond')){
            return parseInt(baseOffer[0].heating_investment_cost)
        }else if(renovation.includes('solar') ){
            return parseInt(baseOffer[0].solar_invsetment_cost)
        }else if(renovation.includes('glazing') ){
            return (parseInt(baseOffer[0].wall_investment_cost / 2))
        }
    }

    /**
     * Sum indivaual renovation cost 
     * @returns total cost.
     */
    const renovationTotalCost = () => {
        let total = 0;
        if(baseOffer){
        baseOffer[0]?.renovation?.split('_').map(ele => {
            total = total + parseInt(renovationCost(ele))            
        }) }
        return total;
    }

    return (
        <Modal className="fade" size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered show={showModal}>
            <Formik initialValues={{}} onSubmit={(values) => { }}>
                {(props) => {
                    const { } = props;
                    return (
                        <>
                            <form action="">
                                <Modal.Header>
                                    <Modal.Title>Base Offer for {userOffer.userName}</Modal.Title>
                                    <Button variant="" className="close" onClick={() => setShowModal(false)}>
                                        <span>&times;</span>
                                    </Button>
                                </Modal.Header>
                                <Modal.Body>
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th>
                                                    <strong>Name</strong>
                                                </th>
                                                <th>
                                                    <strong>Price</strong>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="table_body">
                                            {baseOffer ?  baseOffer[0]?.renovation?.split('_').map(ele => {
                                                return <tr>
                                                    <td>{ele}</td>
                                                    <td>{renovationCost(ele)} €</td>
                                                </tr>
                                            }) :  <></>}
                                        </tbody>
                                    </Table>
                                </Modal.Body>
                                <Modal.Footer>
                                    <h4>Total <span>{renovationTotalCost()} € </span></h4> 
                                </Modal.Footer>
                            </form>
                        </>
                    );
                }}
            </Formik>
        </Modal>
    );
};

export default ViewUserOffer;
