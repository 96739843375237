import { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { CreateCity } from "../../../services/city";
import { CityService } from "../../../services/city";
import { withRouter } from "react-router-dom";

const AddContent = (props) => {
  const { history } = props;
  const [loader, setLoader] = useState(false);
  const [city, setCity] = useState({
    name: "",
    lang: "",
    image: {},
    copied_from_city: "",
  });
  const [getCity, setGetCity] = useState([]);
  const cityId = localStorage.getItem("cityId");
  useEffect(() => {
    CityService()
      .then((response) => {
        setGetCity(response.data.data);
        console.log("res", response);
      })
      .catch((err) => {
        console.log("res", err);
      });
  }, []);

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const uploadImage = (event) => {
    const file = event.target.files[0];
    console.log("file", file);
    let fileRecord = {
      lastModified: file?.lastModified,
      lastModifiedDate: file?.lastModifiedDate,
      name: file?.name,
      size: file?.size,
      type: file?.type,
      webkitRelativePath: file?.webkitRelativePath,
    };
    console.log("fileRecord", fileRecord);
    getBase64(file).then((data) => {
      let final = {
        ...fileRecord,
        imageFile: data,
      };
      if (final) {
        console.log("finale", final);
        let duplicate = { ...city };
        duplicate.image = final;
        setCity(duplicate);
      }
    });
  };

  const addCityHandler = (e) => {
    e.preventDefault();
    setLoader(true);
    CreateCity(city)
      .then((response) => {
        setLoader(false);
        history.push(`/city/${cityId}`);
      })
      .catch((error) => {
        setLoader(false);
        console.log("response", error);
      });
  };
  console.log("city", city);
  return (
    <Card className="p-3">
      <form
        className="py-3"
        onSubmit={(e) => {
          addCityHandler(e);
        }}
      >
        <div className="form-group m-0 py-3">
          <label>City Name</label>
          <input
            type="text"
            className="form-control "
            placeholder=""
            onChange={(e) => {
              let duplicate = { ...city };
              duplicate.name = e.target.value;
              setCity(duplicate);
            }}
            required
          />
        </div>
        <div className="form-group m-0 py-3">
          <label>Copy from Existing City</label>
          <select
            type="text"
            className="form-control "
            placeholder=""
            onChange={(e) => {
              let duplicate = { ...city };
              duplicate.copied_from_city = e.target.value;
              setCity(duplicate);
            }}
          >
            <option value="">none</option>
            {getCity?.map((single) => (
              <option value={single?.id}>{single?.name}</option>
            ))}
          </select>
        </div>
        {/* <div className="form-group m-0 py-3">
					<label>Select Language</label>
					<select
						type="text"
						className="form-control "
						placeholder=""
						onChange={(e) => {
							let duplicate = { ...city };
							duplicate.lang = e.target.value;
							setCity(duplicate);
						}}
					>
						<option value="">Select Language</option>
						<option value="en">English</option>
						<option value="dut">Dutch</option>
					</select>
				</div> */}
        <div className="form-group m-0 py-3">
          <label>City Logo</label>
          <div className="custom-file">
            <input
              name="value"
              type="file"
              className="custom-file-input"
              onChange={(e) => {
                uploadImage(e);
              }}
            />
            <label className="custom-file-label">
              {city?.image?.name ? city?.image?.name : ""}
            </label>
          </div>
        </div>
        <div className="d-flex justify-content-end py-3">
          <Button
            className="btn btn-primary shadow sharp px-4"
            type="submit"
            disabled={loader ? true : false}
          >
            Submit
          </Button>
        </div>
      </form>
    </Card>
  );
};
export default withRouter(AddContent);
