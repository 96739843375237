import React, { useState, useEffect } from "react";
import { Card, Tab, Table, Nav, Button } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { getAuditUsersService, updateUserStatus, uploadOfferService } from "../../../services/audit.service";
import Spinner from "react-bootstrap/Spinner";
import ViewUserOfferModal from "../../../components/modals/ViewUserOffer";


const AddContent = (props) => {
  const { history } = props;
  const [loader, setLoader] = useState(false);
  const [buildings, setBuilding] = useState([]);
  const [formattedAddress, setAddress] = useState('');
  const [statusIsLoading, setIsLoading] = useState();
  const [fileUploading, setFileUploading] = useState();
  const [showModal, setShowModal] = useState(false);
  const [userOffer, setUserOffer] = useState();
  const [resulstPageUrl, setResultPageUrl] = useState(null)

  const [fileName, setFileName]= useState({
    userId: null,
    name: null
  });
  const [status, setStatus]= useState({
    status: null,
    userId: null
  });


  useEffect(() => {
    setLoader(true)
    setAddress(window.location.href.split('?')[1].replace(/-/g, ' '));
    setResultPageUrl(window.location.href.split('?')[2].split('&')[0]+'?gmlid=GRB_OIDN_'+ window.location.href.split('&')[1])

    getAuditUsersService(window.location.href.split('/')[4])
      .then((response) => {
        setLoader(false)
        setBuilding(response.data);
      })
      .catch((err) => {
        setLoader(false)
        console.log("res", err);
      });
  }, [window]);

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const uploadImage = (data) => {
    const file = data.event.target.files[0];
    console.log("file", file);
    let fileRecord = {
      lastModified: file?.lastModified,
      lastModifiedDate: file?.lastModifiedDate,
      name: file?.name,
      size: file?.size,
      type: file?.type,
      webkitRelativePath: file?.webkitRelativePath,
      userId: data.userId,
      userDataId: data.userDataId
    };
    console.log("fileRecord", fileRecord);
    getBase64(file).then((base64) => {
      let final = {
        ...fileRecord,
        file: base64,
      };
      uploadOfferService(final).then(offer=>{
        setFileName({
          userId: final.userId,
          name: final.name
        })
        setFileUploading();
      }).catch(err=> {
        setFileUploading();
      });
      
      // if (final) {
      //   console.log("finale", final);
      //   let duplicate = { ...city };
      //   duplicate.image = final;
      //   setCity(duplicate);
      // }
    });
  };

  return (
    <>
      <ViewUserOfferModal
        showModal={showModal}
        setShowModal={setShowModal}
        userOffer={userOffer}
      />      {/* {state.activeTab === currentTab ? ( */}
      <div className="custom-tab-1 city_view_container">
        <Tab.Container>
          <Tab.Content>
            <Card>
              <Card.Header className="d-flex justify-content-between align-items-center">
                <Card.Title>{formattedAddress}</Card.Title>

              </Card.Header>
              <Card.Body>
                {/* section1 table	*/}
                {!loader ? (
                  <div>
                    <Table responsive>
                      <thead>
                        <tr>
                          {/* <th>
                            <strong>User Id</strong>
                          </th> */}
                          <th className="table-td-width">
                            <strong>Name</strong>
                          </th>
                          <th className="table-td-width">
                            <strong>Email</strong>
                          </th>
                          <th className="table-td-width">
                            <strong >Status</strong>
                          </th>
                          <th  className="table-td-width">
                            <strong>Action</strong>
                          </th>
                          <th  className="table-td-width">
                            <strong>Base Offer</strong>
                          </th>
                          <th  className="table-td-width">
                            <strong></strong>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="table_body">
                        {
                          buildings?.map((building, index) => (

                            <tr>
                              {/* <td>{building?.id}</td> */}
                              <td className="table-td-width">
                                {building?.userName}
                              </td>
                              <td className="table-td-width">{building?.email}</td>
                              <td className="d-flex btns_container">
                                {statusIsLoading === building?.id ? <>Updating...</> :
                                  <select
                                    type="text"
                                    className="form-control w-80p"
                                    placeholder=""
                                    value={
                                      parseInt(status.userId) == parseInt(building?.userId) ? 
                                      parseInt(status.status) : 
                                      parseInt(building?.status)}
                                    onChange={(e) => {
                                      setIsLoading(building?.id)
                                      const data = {
                                        userId: building?.userId,
                                        userDataId: building?.userDataId,
                                        status: e.target.value,
                                      }
                                      updateUserStatus(data).then(res => {
                                        setIsLoading()
                                        setStatus({
                                          status:  data.status,
                                          userId:  building?.userId
                                        });
                                      }).catch(err => {
                                        setIsLoading()
                                      })
                                    }}
                                  >
                                    <option value="">Audit Status</option>
                                    <option value={1}>Simulation</option>
                                    <option value={2}>Audit Booked</option>
                                    <option value={3}>Audit Done</option>
                                    <option value={4}>Offer Requested</option>
                                    <option value={5}>Offer Received</option>
                                  </select>
                                }

                              </td>
                              <td className="table-td-file-width" >
                                <div className="custom-file w-80p">
                                      <input
                                        name="value"
                                        type="file"
                                        className="custom-file-input"
                                        onChange={(e) => {
                                          setFileUploading(building?.id)
                                          const data = {
                                            event: e,
                                            userId: building?.userId,
                                            userDataId: building?.userDataId,
                                          }
                                          uploadImage(data);
                                        }}
                                      />
                                      <label className="custom-file-label">
                                      {fileUploading === building?.id ? <>Uploading...</> :
                                      fileName.userId === building?.userId ? "Uploaded" /*fileName.name */:
                                       building?.fileUrl ? "Uploaded" /*building?.fileUrl.split('/')[3].split('.')[0]+'.pdf'*/
                                       : "Upload pdf"}
                                      </label>
                                </div>
                              </td>
                           
                            <td className="table-td-width">
                            {building?.base_offer &&
                            <Button
                            onClick={() => {
                              setShowModal(true);
                              setUserOffer(building);
                            }}
                            >
                             <i className="fa fa-eye"></i>
                            </Button>
                            }
                          </td>

                          <td className="table-td-width">
                            {resulstPageUrl &&
                           <a href={`${resulstPageUrl}&&u=${btoa(building?.userId)}`} target="_blank" >
                             <i className="fa fa-eye"></i>
                           </a>
                            }
                          </td>
                          
                            </tr>
                          ))
                        }
                      </tbody>
                    </Table>
                  </div>
                ) : (
                  <div className="row  py-5 d-flex justify-content-center">
                    <Spinner animation="border" className="m-auto" />
                  </div>
                )}
              </Card.Body>
            </Card>
          </Tab.Content>
        </Tab.Container>
      </div>

    </>
  );
};
export default withRouter(AddContent);
