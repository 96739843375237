import Axios from "axios";
import { removePTag } from "./removePTag";
let API_URL = process.env.REACT_APP_BACKEND_URL;

// seprate faqs call
export const CreateFAQ = (values) => {
  values.description = removePTag(values.description);
  return Axios.post(API_URL + `/faq`, values);
};
export const GetAllFaqsByService = (id) => {
  return Axios.get(API_URL + `/faqs/city/${id}`);
};
export const DeleteSingleFaqByService = (id) => {
  return Axios.delete(API_URL + `/faq/` + id);
};
export const UpdateSingleFaqByService = (values) => {
  values.description = removePTag(values.description);
  return Axios.put(API_URL + `/faq`, values);
};
export const GetSingleContentByService = (id) => {
  return Axios.get(API_URL + `/faq/single/${id.id}`);
};

// faqs call of result page
export const FaqContentByService = (city_id, datatype) => {
  return Axios.post(API_URL + `/getCityDataByType`, { city_id, datatype });
};
export const UpdateFAQByService = (values) => {

  return Axios.post(API_URL + "/updatepagewithcontent", values);
};
export const GetSingleFAQByService = (id) => {
  return Axios.post(API_URL + "/getSinglePageData", id);
};
