import { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { Button } from "react-bootstrap";
import {
  GetSingleContentByService,
  UpdateSingleFaqByService,
} from "../../../services/faqs";
const ReactQuill = typeof window === 'object' ? require('react-quill') : () => false;

const EditContent = (props) => {
  const { match, history } = props;
  const id = match?.params?.id;
  const [menuContent, setmenuContent] = useState({});
  let menuId = localStorage.getItem("menuId");
  useEffect(() => {
    let city_id = { id: id };
    GetSingleContentByService(city_id)
      .then((response) => {
        console.log(response?.data);
        setmenuContent(response?.data);
      })
      .catch((error) => {
        console.log("res", error);
      });
  }, []);

  const editMenuContentHandler = (e) => {
    e.preventDefault();
    UpdateSingleFaqByService(menuContent)
      .then((response) => {
        history.push(`/faqs/${menuId}`);
      })
      .catch((error) => {
        console.log("response", error);
      });
  };

  return (
    <Card className="p-3">
      <form
        className="py-3"
        onSubmit={(e) => {
          editMenuContentHandler(e);
        }}
      >
        <div className="form-group m-0 py-3">
          <label>Title</label>
          <input
            type="text"
            className="form-control "
            placeholder=""
            value={menuContent?.title}
            onChange={(e) => {
              let duplicate = { ...menuContent };
              duplicate.title = e.target.value;
              setmenuContent(duplicate);
            }}
          />
        </div>
        <div className="form-group m-0 py-3">
          <div>
            <label>Description</label>
            <div className="react_quill_container_faq">
            {menuContent?.description && <ReactQuill 
             theme="snow"
             value={menuContent?.description }
                   onChange={(value) => {
                    let duplicate = { ...menuContent };
                    duplicate.description = value;
                    setmenuContent(duplicate);
                   }} />}
              </div>
            {/* <textarea
              // rows="6"
              type="text"
              className="form-control pt-3 pb-0"
              placeholder=""
              value={menuContent?.description}
              onChange={(e) => {
                let duplicate = { ...menuContent };
                duplicate.description = e.target.value;
                setmenuContent(duplicate);
              }}
            /> */}
          </div>
        </div>
        <div className="form-group m-0 py-3">
          <label>Category</label>
          <select
            type="text"
            className="form-control "
            placeholder=""
            value={menuContent?.category}
            onChange={(e) => {
              let duplicate = { ...menuContent };
              duplicate.category = e.target.value;
              setmenuContent(duplicate);
            }}
          >
            <option value="">Select Category</option>
            <option value="1">Neighborhood</option>
            <option value="2">Support for subsidies & practicalities</option>
            <option value="3">Renovations</option>
            <option value="4">How it works</option>
          </select>
        </div>
        <div className="d-flex justify-content-end py-3">
          <Button className="btn btn-primary shadow sharp px-4" type="submit">
            Submit
          </Button>
        </div>
      </form>
    </Card>
  );
};
export default EditContent;
