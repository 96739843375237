import React, { useState } from "react";

/// React router dom
import { Link } from "react-router-dom";

/// images
import logo from "../../../images/agc.png";
import logoText from "../../../images/logo-text.png";

const NavHader = () => {
	const [toggle, setToggle] = useState(false);
	return (
		<div className="nav-header" style={{ zIndex: "12" }}>
			<Link to="/" className="brand-logo">
				{/* <img className="logo-abbr" src={logo} alt="" /> */}
				{/* <img className="logo-compact" src={logoText} alt="" /> */}
				<img className="brand-title" src={logo} alt="" />
				{/* <img
					className="logo-abbr"
					src={
						"https://resources-live.sketch.cloud/files/7a274768-2faf-407b-be63-07b732f0f07c.png?Expires=1632564000&Signature=gbqaXs8NRtfCJc~yxIYQjcyhL8d4gOyUsfkCtPws8JCF-I2x-bblu-R7jWOxTShjMa~hFc4z3qbxV7GRWEdvTaTSMIyLP0O63-pKLjad3kze4mNzEx1GgNWSJj4lrHMVnO3sq5AW3~HuRZbo-rvH0RZkqBw8KVHJeKM5E5J3CI8_&Key-Pair-Id=APKAJOITMW3RWOLNNPYA"
					}
					alt=""
				/>
				<img
					className="brand-title"
					src={
						"https://resources-live.sketch.cloud/files/7a274768-2faf-407b-be63-07b732f0f07c.png?Expires=1632564000&Signature=gbqaXs8NRtfCJc~yxIYQjcyhL8d4gOyUsfkCtPws8JCF-I2x-bblu-R7jWOxTShjMa~hFc4z3qbxV7GRWEdvTaTSMIyLP0O63-pKLjad3kze4mNzEx1GgNWSJj4lrHMVnO3sq5AW3~HuRZbo-rvH0RZkqBw8KVHJeKM5E5J3CI8_&Key-Pair-Id=APKAJOITMW3RWOLNNPYA"
					}
					alt=""
				/> */}
			</Link>
			<div className="nav-control" onClick={() => setToggle(!toggle)}>
				<div className={`hamburger ${toggle ? "is-active" : ""}`}>
					<span className="line"></span>
					<span className="line"></span>
					<span className="line"></span>
				</div>
			</div>
		</div>
	);
};

export default NavHader;
