import { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Card } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { UpdateTestimonialService, GetSingleTestimonial } from "../../../services/testimonial";
const ReactQuill = typeof window === 'object' ? require('react-quill') : () => false;

const EditContent = (props) => {
	const { match, history } = props;
	const id = match?.params?.id;
	const [loader, setLoader] = useState(false);
	const [formData, setFormData] = useState({
		name: "",
		address: "",
		image: {},
		cityId: localStorage.getItem("cityId"),
		description: "",
	  });
	  	
	  const {
		name,
		address,
		image,
		// cityId,
		description,
	  } = formData;
	  const [desc, setDescription] = useState(description);
	
	  const onChangeFormData = (e) => {
		if (e.target.name == "image") {
		  setFormData({ ...formData, [e.target.name]: e.target.files[0] });
		} else {
		  setFormData({ ...formData, [e.target.name]: e.target.value });
		}
	  };
	
	  const getBase64 = (file) => {
		return new Promise((resolve, reject) => {
		  const reader = new FileReader();
		  reader.readAsDataURL(file);
		  reader.onload = () => resolve(reader.result);
		  reader.onerror = (error) => reject(error);
		});
	  };
	
	  const uploadImage = (event) => {
		const file = event.target.files[0];
		let fileRecord = {
		  lastModified: file?.lastModified,
		  lastModifiedDate: file?.lastModifiedDate,
		  name: file?.name,
		  size: file?.size,
		  type: file?.type,
		  webkitRelativePath: file?.webkitRelativePath,
		};
	
		getBase64(file).then((data) => {
		  let final = {
			...fileRecord,
			imageFile: data,
		  };
		  if (final) {
			setFormData({ ...formData, image: final });
		  }
		});
	  };

	useEffect(() => {
		GetSingleTestimonial(id)
			.then((response) => {
				setFormData(response?.data);
				setDescription(response?.data.description)
			})
			.catch((error) => {
				console.log("res", error);
			});
		
	}, []);

	const updateTestimonial = (e) => {
		e.preventDefault();
		setLoader(true);
		formData.description = desc;
		UpdateTestimonialService(formData)
			.then((response) => {
				setLoader(false);
				history.push(`/testimonials/${id}`);
			})
			.catch((error) => {
				setLoader(false);
				console.log("response", error);
			});
	};


	return (
		<Card className="p-3">
			
			<form
				className="py-3"
				onSubmit={(e) => {
					updateTestimonial(e);
				}}
			>
				<div className="form-group m-0 py-3">
					<label>Name</label>
					<input
						type="text"
						className="form-control"
						placeholder=""
						name="name"
						value={name}
						onChange={(e) => onChangeFormData(e)}
						required
					/>
				</div>
				<div className="form-group m-0 py-3">
					<label>Address</label>
					<textarea
						type="text"
						className="form-control py-2"
						placeholder=""
						name="address"
						value={address}
						onChange={(e) => onChangeFormData(e)}
						required
					/>
				</div>
				<div className="form-group m-0 py-3">
					<label>Description</label>
					<div className="react_quill_container">
					<ReactQuill 
             theme="snow"
             value={desc}
                   onChange={(value) => {
                    setDescription( value );
                   }} />
				   </div>
					{/* <textarea
						type="text"
						className="form-control"
						placeholder=""
						name="description"
						value={description}
						onChange={(e) => onChangeFormData(e)}
						required
					/> */}
				</div>
				<div className="form-group m-0 py-3">
          <label>Choose Image</label>
          <div className="custom-file">
            <input
              name="value"
              type="file"
              className="custom-file-input"
              name="image"
              onChange={
                (e) => uploadImage(e)
              }
            />
            <label className="custom-file-label">
              {image ? image?.name : "Choose File"}
            </label>
          </div>
        </div>
				<div className="d-flex justify-content-end py-3">
					<Button className="btn btn-primary shadow sharp px-4" type="submit" disabled={loader ? true : false}>
						Submit
					</Button>
				</div>
			</form>
		</Card>
	);
};
export default withRouter(EditContent);
