import Axios from "axios";
let API_URL = process.env.REACT_APP_BACKEND_URL;

export const CityService = () => {
	return Axios.get(API_URL + `/getAllCityData`);
};

export const updateCity = (values) => {
	return Axios.post(API_URL + "/city/update", values);
};

export const getSingleCity = (city_id) => {
	return Axios.post(API_URL + "/city/show", city_id);
};

export const deleteCity = (id) => {
	return Axios.get(API_URL + `/city/delete/${id}`);
};

export const CreateCity = (values) => {
	return Axios.post(API_URL + "/city/create", values);
};
