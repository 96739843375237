import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Card, Tab, Table, Nav, Button } from "react-bootstrap";
import {
  GetAllTestimonialService,
  DeleteTestimonialService,
} from "../../../services/testimonial";
import Spinner from "react-bootstrap/Spinner";
import WarningModal from "../../../components/modals/WarningModal";
import { Markup } from 'interweave';

const ViewCity = (props) => {
  let { history } = props;
  const [removeTestimonial, setRemoveTestimonials] = useState(0);
  const [loader, setLoader] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [testmonials, setTestimonials] = useState([]);

  useEffect(() => {
    setLoader(true);
    GetAllTestimonialService()
      .then((response) => {
        setTestimonials(response.data);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
  }, []);

  const deleteCityHandler = () => {
    DeleteTestimonialService(removeTestimonial)
      .then((response) => {
        let record = testmonials.filter(
          (single) => single?.id !== removeTestimonial
        );
        setShowModal(false);
        setTestimonials(record);
      })
      .catch((error) => {
        console.log("res", error);
      });
  };

  return (
    <>
      <WarningModal
        showModal={showModal}
        setShowModal={setShowModal}
        deleteHandler={deleteCityHandler}
      />
      {/* {state.activeTab === currentTab ? ( */}
      <div className="custom-tab-1 city_view_container">
        <Tab.Container>
          <Tab.Content>
            <Card>
              <Card.Header className="d-flex justify-content-between align-items-center">
                <Card.Title>Testimonials</Card.Title>
                <Card.Title>
                  <Button
                    className="btn btn-primary shadow btn m-0 sharp mr-1"
                    onClick={() => {
                      history.push("/add-testimonial");
                    }}
                  >
                    Add Testimonial
                  </Button>
                </Card.Title>
              </Card.Header>
              <Card.Body>
                {/* section1 table	*/}
                {!loader ? (
                  <div>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>
                            <strong>Name</strong>
                          </th>
                          <th>
                            <strong>Address</strong>
                          </th>
                          <th>
                            <strong>Description</strong>
                          </th>
                          <th>
                            <strong>Image</strong>
                          </th>
                          <th>
                            <strong>Action</strong>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="table_body">
                        {testmonials?.map((tst, index) => (
                          <tr key={index}>
                            <td>{tst?.name}</td>
                            <td>{tst?.address}</td>
                            <td><Markup content={tst?.description} /></td>
                            <td>
                              {tst?.image ? (
                                <img className="h_80" src={tst?.image} alt="" />
                              ) : (
                                "no-image"
                              )}
                            </td>
                            {/* <td>{city?.copied_from_city}</td> */}
                            <td className="d-flex btns_container">
                              <Button
                                className="btn btn-primary shadow btn sharp mr-1"
                                onClick={() => {
                                  history.push(`/edit-testimonial/${tst?.id}`);
                                }}
                              >
                                <i className="fa fa-pencil"></i>
                              </Button>
                              <Button
                                className="btn btn-danger shadow btn sharp mr-1"
                                onClick={() => {
                                  setShowModal(true);
                                  setRemoveTestimonials(tst?.id);
                                }}
                              >
                                <i className="fa fa-trash-o"></i>
                              </Button>
                              {/* <Button
																variant={city?.id === parseInt(cityId) ? "success py-2 px-3" : "outline-secondary py-2 px-3"}
																onClick={() => {
																	localStorage.setItem("cityId", city?.id);
																	localStorage.setItem("cityName", city?.name);
																	history.push(`/city/${city?.id}`);
																}}
																disabled={city?.id === parseInt(cityId) ? true : false}
															>
																{city?.id === parseInt(cityId) ? "Activated City" : "Switch City"}
															</Button> */}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                ) : (
                  <div className="row  py-5 d-flex justify-content-center">
                    <Spinner animation="border" className="m-auto" />
                  </div>
                )}
              </Card.Body>
            </Card>
          </Tab.Content>
        </Tab.Container>
      </div>
      {/* <div className="row">
				<Spinner animation="border" className="m-auto" />
			</div> */}
    </>
  );
};
export default withRouter(ViewCity);
