import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { Card, Tab, Nav } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import Table from "../../components/customTable/table";
import ContactModal from "../../components/modals/ContactModal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Contact = () => {
  const [currentTab, setCurrentTab] = useState(false);
  const [state, setState] = useState({
    activeTab: "",
    basicModal: false,
    tabData: [],
    data: [],
  });

  const tabData = [
    {
      name: "Official Distributor",
    },
    {
      name: "Office",
    },
    {
      name: "Stock point",
    },
  ];

  const tableHeader = [
    "#",
    "Title",
    "Name",
    "Rank",
    "Email",
    "Phone",
    "Address",
    "Type",
    "Action",
  ];

  const getCall = (tab) => {
    axios
      .get(process.env.REACT_APP_BACKEND_URL + `contact/`)

      .then((response) => {
        const data = response.data.data;
        if (tab) {
          setCurrentTab(tab);
          setState((st) => ({
            ...st,
            activeTab: tab,
            data: data,
          }));
        } else {
          setCurrentTab(tabData[0].name);
          setState((st) => ({
            ...st,
            activeTab: tabData[0].name,
            data: data,
          }));
        }
      });
  };
  useEffect(() => {
    getCall();
  }, []);
  const setRecord = useCallback((record) => {
    setState((st) => ({ ...st, record, basicModal: true }));
  }, []);
  const filterData = state.data.filter((obj) => obj.type === state.activeTab);
  // Notification
  const notify = (status, text) => {
    if (status == "success") {
      toast.success(`✅ ${text}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else if (status == "error") {
      toast.error(`❌ ${text} !`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
    }
  };
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* Parent Modal */}
      <ContactModal
        StateData={state}
        SetState={setState}
        GetData={getCall}
        Tab="contact"
        setNotification={notify}
      />
      {state.activeTab === currentTab ? (
        <Card>
          <Card.Header>
            <Card.Title>Contact </Card.Title>
          </Card.Header>
          <Card.Body>
            {/* <!-- Nav tabs --> */}
            <div className="custom-tab-1">
              <Tab.Container defaultActiveKey={state.activeTab}>
                <Nav as="ul" className="nav-tabs">
                  {tabData.map((data, i) => (
                    <Nav.Item as="li" key={i}>
                      <Nav.Link
                        onClick={() => {
                          setCurrentTab(data.name);
                          setState((st) => ({
                            ...st,
                            activeTab: data.name,
                          }));
                        }}
                        eventKey={data.name}
                      >
                        {/* <i className={`la la-${data.icon} mr-2`} /> */}
                        {data.name}
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
                <Tab.Content activeTab={state.activeTab} className="pt-4">
                  <Tab.Pane eventKey={state.activeTab}>
                    <Table
                      data={filterData}
                      headers={tableHeader}
                      onEdit={setRecord}
                      pageName="contact"
                    />
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </Card.Body>
        </Card>
      ) : (
        <div className="row">
          <Spinner animation="border" className="m-auto" />
        </div>
      )}
    </>
  );
};
export default Contact;
