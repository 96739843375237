import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Card, Tab, Table, Nav, Button } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { FaqContentByService } from "../../../../services/faqs";

const ViewHomeContent = (props) => {
	let { history } = props;
	const [faqs, setFaqs] = useState([]);
	const [loader, setLoader] = useState(true);
	const cityId = localStorage.getItem("cityId");
	useEffect(() => {
		setLoader(true);
		FaqContentByService(cityId, "faq")
			.then((response) => {
				setFaqs(response?.data?.data?.pages);
				setLoader(false);
			})
			.catch((error) => {
				setLoader(false);
			});
	}, []);
	return (
		<>
			<div className="custom-tab-1">
				<Tab.Container defaultActiveKey={""}>
					<Tab.Content activeTab={""}>
						<Card>
							<Card.Header>
								<Card.Title>Home Page</Card.Title>
							</Card.Header>
							<Card.Body>
								{!loader ? (
									<div>
										<h4 className="py-2">FAQ</h4>
										<Table responsive className="my-3">
											<thead>
												<tr>
													<th>
														<strong>Title</strong>
													</th>
													<th>
														<strong>Answer</strong>
													</th>
													<th className="text-right">
														<strong>Action</strong>
													</th>
												</tr>
											</thead>
											<tbody>
												{faqs?.map((faqs, index) => (
													<tr key={index}>
														<td>{faqs?.title} </td>
														<td>{faqs?.page_contents[0]?.value}</td>
														<td className="text-right btns_container">
															<Button
																className="btn btn-primary shadow btn sharp mr-1"
																onClick={() => {
																	history.push(`/edit-faq/${faqs?.id}`);
																}}
															>
																<i className="fa fa-pencil"></i>
															</Button>
														</td>
													</tr>
												))}
											</tbody>
										</Table>
									</div>
								) : (
									<div className="row  py-5 d-flex justify-content-center">
										<Spinner animation="border" className="m-auto" />
									</div>
								)}
							</Card.Body>
						</Card>
					</Tab.Content>
				</Tab.Container>
			</div>
		</>
	);
};
export default withRouter(ViewHomeContent);
