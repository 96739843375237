import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { Card, Tab, Table, Nav, Button, Dropdown } from "react-bootstrap";
// import Tab from 'react-bootstrap/Tab';
// import Tab2 from 'react-bootstrap/Tab2';
import {
  GetAllTestimonialService,
  DeleteTestimonialService,
  GetAllOverHeadService,
} from "../../../services/testimonial";
import Spinner from "react-bootstrap/Spinner";
import WarningModal from "../../../components/modals/WarningModal";
import { Markup } from "interweave";

const ViewCity = (props) => {
  let { history } = props;
  const [selectedLanguage, setSelectedLanguage] = useState(2);
  const [loader, setLoader] = useState(false);
  const [testmonials, setTestimonials] = useState([]);
  const cityId = localStorage.getItem("cityId");

  const getContentHandler = (language) => {
    setLoader(true);
    GetAllOverHeadService(cityId, language)
      .then((response) => {
        setTestimonials(response.data?.data);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  useEffect(() => {
    getContentHandler(selectedLanguage);
  }, []);

  // const deleteCityHandler = () => {
  //   DeleteTestimonialService(removeTestimonial)
  //     .then((response) => {
  //       let record = testmonials.filter(
  //         (single) => single?.id !== removeTestimonial
  //       );
  //       setShowModal(false);
  //       setTestimonials(record);
  //     })
  //     .catch((error) => {
  //       console.log("res", error);
  //     });
  // };

  return (
    <>
      {/* <WarningModal
        showModal={showModal}
        setShowModal={setShowModal}
        deleteHandler={deleteCityHandler}
      /> */}
      {/* {state.activeTab === currentTab ? ( */}
      <div className="custom-tab-1 city_view_container">
        <Tab.Container>
          <Tab.Content>
            <Card>
              <Card.Header className="d-flex justify-content-between align-items-center">
                <Card.Title>Overhead Page</Card.Title>
                <Card.Title className="d-flex align-items-center">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      className="py-2 px-3 mr-2"
                    >
                      {selectedLanguage === 2 ? "FR" : "NL"}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          getContentHandler(selectedLanguage === 2 ? 1 : 2);
                          setSelectedLanguage(selectedLanguage === 2 ? 1 : 2);
                        }}
                      >
                        {selectedLanguage === 1 ? "FR" : "NL"}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  {/*  */}
                  <Button
                    className="btn btn-primary shadow btn m-0 sharp mr-1"
                    onClick={() => {
                      history.push(
                        `/edit-overhead/${cityId}?language=${
                          selectedLanguage === 2 ? "fr" : "nl"
                        }`
                      );
                    }}
                  >
                    Edit
                  </Button>
                </Card.Title>
              </Card.Header>
              <Card.Body>
                {!loader ? (
                  <div>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>
                            <strong>Key</strong>
                          </th>
                          <th>
                            <strong>Value</strong>
                          </th>
                          {/* <th>
                            <strong>Action</strong>
                          </th> */}
                        </tr>
                      </thead>
                      <tbody className="table_body">
                        <tr>
                          <td>Title</td>
                          <td>{testmonials?.title}</td>
                        </tr>
                        <tr>
                          <td>Description</td>
                          <td>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: testmonials?.description,
                              }}
                            ></span>
                          </td>
                        </tr>
                        <tr>
                          <td>Logo 1 Name</td>
                          <td>{testmonials?.desc_1}</td>
                        </tr>
                        <tr>
                          <td>Logo 2 Name</td>
                          <td>{testmonials?.desc_2}</td>
                        </tr>
                      </tbody>
                      {/* <td>Ac</td> */}
                      {/* {testmonials?.map((tst, index) => (
                          <tr key={index}>
                            <td>{tst?.name}</td>
                            <td>{tst?.address}</td>
                            <td><Markup content={tst?.description} /></td>
                            <td>
                              {tst?.image ? (
                                <img className="h_80" src={tst?.image} alt="" />
                              ) : (
                                "no-image"
                              )}
                            </td>
                            <td className="d-flex btns_container">
                              <Button
                                className="btn btn-primary shadow btn sharp mr-1"
                                onClick={() => {
                                  history.push(`/edit-overhead/${tst?.id}`);
                                }}
                              >
                                <i className="fa fa-pencil"></i>
                              </Button>
                              
                            </td>
                          </tr>
                        ))} */}
                    </Table>
                  </div>
                ) : (
                  <div className="row  py-5 d-flex justify-content-center">
                    <Spinner animation="border" className="m-auto" />
                  </div>
                )}
              </Card.Body>
            </Card>
          </Tab.Content>
        </Tab.Container>
      </div>
      {/* <div className="row">
				<Spinner animation="border" className="m-auto" />
			</div> */}
    </>
  );
};
export default withRouter(ViewCity);
