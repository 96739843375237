import React, { useCallback, useEffect, useState, useRef } from "react";
import { Card, Tab, Nav, Modal, Button } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";

const DetailModal = ({
  StateData,
  SetState,
  GetData,
  Tab,
  setNotification,
}) => {
  // Schema for yup
  const validationSchema = Yup.object().shape({
    value: Yup.string().required("* Value is required"),
    // .min(8, "* Password must be 8 digits long"),
  });
  return (
    <Modal className="fade" show={StateData.detailModal}>
      <Formik
        initialValues={{
          value: StateData?.record?.value || "",
        }}
        onSubmit={(values) => {
          let formData = new FormData();
          formData.append("value", values.value);
          axios
            .put(
              process.env.REACT_APP_BACKEND_URL +
                "" +
                Tab +
                "/updatedetails/" +
                StateData?.record?.id,
              formData
            )
            .then((response) => {
              if (response.data.success === true) {
                SetState((st) => ({ ...st, detailModal: false }));
                GetData(StateData.activeTab);
                setNotification("success", "Record Updated");
              }
            })
            .catch((error) => {
              error.response.statusText == ""
                ? setNotification("error", error.response.data.data.message)
                : setNotification("error", error.response.statusText);
            });
        }}
        // Hooks up our validationSchema to Formik
        validationSchema={validationSchema}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          } = props;
          return (
            <>
              <form action="" onSubmit={handleSubmit}>
                <Modal.Header>
                  <Modal.Title>Edit Content</Modal.Title>
                  <Button
                    variant=""
                    className="close"
                    onClick={() =>
                      SetState((st) => ({ ...st, detailModal: false }))
                    }
                  >
                    <span>&times;</span>
                  </Button>
                </Modal.Header>
                <Modal.Body>
                  <div className="form-row">
                    <div className="form-group col-md-12">
                      <label>{StateData?.record?.key}</label>
                      {StateData?.record?.key == "paragraph_description" ? (
                        <textarea
                          className="form-control"
                          value={values.value}
                          name="value"
                          onChange={handleChange}
                          rows="8"
                        />
                      ) : StateData?.record?.key == "image" ? (
                        <div className="custom-file">
                          <input
                            name="value"
                            type="file"
                            className="custom-file-input"
                            // values={values.value}
                            onChange={(event) => {
                              setFieldValue(
                                "value",
                                event.currentTarget.files[0]
                              );
                            }}
                          />
                          <label className="custom-file-label">
                            Choose file
                          </label>
                        </div>
                      ) : (
                        <input
                          type="text"
                          className="form-control"
                          name="value"
                          placeholder="Type here"
                          value={values.value}
                          onChange={handleChange}
                        />
                      )}
                    </div>
                    <div
                      id="val-username1-error"
                      className="invalid-feedback animated fadeInUp"
                      style={{ display: "block" }}
                    >
                      {errors.value && errors.value}
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    onClick={() =>
                      SetState((st) => ({ ...st, detailModal: false }))
                    }
                    variant="danger light btn-sm"
                  >
                    Close
                  </Button>
                  <input
                    type="submit"
                    value="Save changes"
                    className="btn btn-primary btn-sm"
                  />
                </Modal.Footer>
              </form>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default DetailModal;
