import React, { useCallback, useEffect, useState, useRef } from "react";
import { Card, Tab, Nav, Modal, Button } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";

const ContactModal = ({
  StateData,
  SetState,
  GetData,
  Tab,
  setNotification,
}) => {
  // RegEx for phone number validation
  const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
  // Schema for yup
  var validationSchema = Yup.object().shape({
    type: Yup.string().required("* Tyoe is required"),
    address: Yup.string().required("* Address is required"),
    email: Yup.string()
      .email("*Must be a valid email address")
      .max(100, "*Email must be less than 100 characters"),
    phone: Yup.string().matches(phoneRegExp, "*Phone number is not valid"),
    //.required('*Phone number required'),
    // .min(8, "* Password must be 8 digits long"),
  });

  return (
    <Modal className="fade" show={StateData.basicModal} size="lg">
      <Formik
        initialValues={{
          title: StateData?.record?.title || "",
          name: StateData?.record?.name || "",
          rank: StateData?.record?.rank || "",
          email: StateData?.record?.email || "",
          phone: StateData?.record?.phone || "",
          address: StateData?.record?.address || "",
          type: StateData?.record?.type || "",
        }}
        onSubmit={(values) => {
          axios
            .put(
              process.env.REACT_APP_BACKEND_URL +
                "" +
                Tab +
                "/update/" +
                StateData?.record?.id,
              values
            )
            .then((response) => {
              if (response.data.success === true) {
                SetState((st) => ({ ...st, basicModal: false }));
                GetData(values.type);
                setNotification("success", "Record Updated");
              }
            })
            .catch((error) => {
              error.response.statusText == ""
                ? setNotification("error", error.response.data.data.message)
                : setNotification("error", error.response.statusText);
            });
        }}
        // Hooks up our validationSchema to Formik
        validationSchema={validationSchema}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          } = props;
          return (
            <>
              <form action="" onSubmit={handleSubmit}>
                <Modal.Header>
                  <Modal.Title>Edit Content</Modal.Title>
                  <Button
                    variant=""
                    className="close"
                    onClick={() =>
                      SetState((st) => ({ ...st, basicModal: false }))
                    }
                  >
                    <span>&times;</span>
                  </Button>
                </Modal.Header>
                <Modal.Body>
                  <div className="form-row">
                    <div className="form-group col-md-6">
                      <label>Title</label>
                      <input
                        type="text"
                        className="form-control"
                        name="title"
                        placeholder="Type here"
                        value={values.title}
                        onChange={handleChange}
                      />
                      <div
                        id="val-username1-error"
                        className="invalid-feedback animated fadeInUp"
                        style={{ display: "block" }}
                      >
                        {errors.title && errors.title}
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <label>Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        placeholder="Type here"
                        value={values.name}
                        onChange={handleChange}
                      />
                      <div
                        id="val-username1-error"
                        className="invalid-feedback animated fadeInUp"
                        style={{ display: "block" }}
                      >
                        {errors.name && errors.name}
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <label>Rank</label>
                      <input
                        type="text"
                        className="form-control"
                        name="rank"
                        placeholder="Type here"
                        value={values.rank}
                        onChange={handleChange}
                      />
                      <div
                        id="val-username1-error"
                        className="invalid-feedback animated fadeInUp"
                        style={{ display: "block" }}
                      >
                        {errors.rank && errors.rank}
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <label>Email</label>
                      <input
                        type="text"
                        className="form-control"
                        name="email"
                        placeholder="Type here"
                        value={values.email}
                        onChange={handleChange}
                      />
                      <div
                        id="val-username1-error"
                        className="invalid-feedback animated fadeInUp"
                        style={{ display: "block" }}
                      >
                        {errors.email && errors.email}
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <label>Phone</label>
                      <input
                        type="text"
                        className="form-control"
                        name="phone"
                        placeholder="Type here"
                        value={values.phone}
                        onChange={handleChange}
                      />
                      <div
                        id="val-username1-error"
                        className="invalid-feedback animated fadeInUp"
                        style={{ display: "block" }}
                      >
                        {errors.phone && errors.phone}
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <label>Address</label>
                      <input
                        type="text"
                        className="form-control"
                        name="phone"
                        placeholder="Type here"
                        value={values.address}
                        onChange={handleChange}
                      />
                      <div
                        id="val-username1-error"
                        className="invalid-feedback animated fadeInUp"
                        style={{ display: "block" }}
                      >
                        {errors.address && errors.address}
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <label>Type</label>
                      <input
                        type="text"
                        className="form-control"
                        name="phone"
                        placeholder="Type here"
                        value={values.type}
                        onChange={handleChange}
                      />
                      <div
                        id="val-username1-error"
                        className="invalid-feedback animated fadeInUp"
                        style={{ display: "block" }}
                      >
                        {errors.type && errors.type}
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    onClick={() =>
                      SetState((st) => ({ ...st, basicModal: false }))
                    }
                    variant="danger light btn-sm"
                  >
                    Close
                  </Button>
                  {/* <input
              variant="primary btn-sm"
              type="submit"
              value="Save changes"
            /> */}
                  <input
                    type="submit"
                    value="Save changes"
                    className="btn btn-primary btn-sm"
                  />
                </Modal.Footer>
              </form>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default ContactModal;
