import React from "react";
import { Modal, Button } from "react-bootstrap";
import { Formik } from "formik";

const DetailModal = ({ title, showModal, setShowModal, deleteHandler }) => {
	return (
		<Modal className="fade" show={showModal}>
			<Formik initialValues={{}} onSubmit={(values) => {}}>
				{(props) => {
					const {} = props;
					return (
						<>
							<form action="">
								<Modal.Header>
									<Modal.Title>Delete</Modal.Title>
									<Button variant="" className="close" onClick={() => setShowModal(false)}>
										<span>&times;</span>
									</Button>
								</Modal.Header>
								<Modal.Body>
									<div className="form-row">
										<span className="text-center">{title ? title : "Are you sure you want to delete?"}</span>
									</div>
								</Modal.Body>
								<Modal.Footer>
									<Button onClick={() => setShowModal(false)} variant="danger light btn-sm">
										Cancel
									</Button>
									<Button
										onClick={() => {
											deleteHandler();
											setShowModal(false);
										}}
										variant="btn btn-primary btn-sm"
									>
										Delete
									</Button>
								</Modal.Footer>
							</form>
						</>
					);
				}}
			</Formik>
		</Modal>
	);
};

export default DetailModal;
