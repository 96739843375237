import { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { Button } from "react-bootstrap";
import {
  GetSingleMenuContentByService,
  UpdateSingleMenuContentByService,
} from "../../../../services/menus";
const ReactQuill = typeof window === 'object' ? require('react-quill') : () => false;

const EditContent = (props) => {
  const { match, history } = props;
  const id = match?.params?.id;
  const [menuContent, setmenuContent] = useState({});
  let menuId = localStorage.getItem("menuId");
  const [formData, setFormData] = useState({
		image: {}
  });
  const {image} = formData;
  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const uploadImage = (event) => {
		const file = event.target.files[0];
		let fileRecord = {
		  lastModified: file?.lastModified,
		  lastModifiedDate: file?.lastModifiedDate,
		  name: file?.name,
		  size: file?.size,
		  type: file?.type,
		  webkitRelativePath: file?.webkitRelativePath,
		};
	
		getBase64(file).then((data) => {
		  let final = {
			...fileRecord,
			imageFile: data,
		  };
		  if (final) {
			setFormData({ ...formData, image: final });
		  }
		});
	  };

  useEffect(() => {
    let city_id = { id: id };
    GetSingleMenuContentByService(city_id)
      .then((response) => {
        setmenuContent(response?.data?.data);
      })
      .catch((error) => {
        console.log("res", error);
      });
  }, []);

  const editMenuContentHandler = (e) => {
    e.preventDefault();
   if(formData.image) {
    menuContent.image = formData.image;
  } else {
    menuContent.image = '';
  }
    UpdateSingleMenuContentByService(menuContent)
      .then((response) => {
        history.push(`/result-menu-content/${menuId}`);
      })
      .catch((error) => {
        console.log("response", error);
      });
  };

  const inputHandler = (key) => {
    switch (key) {
      case "sub_title":
      case "hover_info":
      case "description":
        return (
          <div>
            <label>Value</label>
            {menuContent?.value &&
           <div className="react_quill_container">
           <ReactQuill 
             theme="snow"
             value={menuContent?.value}
                   onChange={(value) => {
                     let duplicate = { ...menuContent };
                     duplicate.value = value;
                     setmenuContent(duplicate);
                   }} />
              </div>
             }
            {/* <textarea
              rows="6"
              type="text"
              className="form-control "
              placeholder=""
              value={menuContent?.value}
              onChange={(e) => {
                let duplicate = { ...menuContent };
                duplicate.value = e.target.value;
                setmenuContent(duplicate);
              }}
            /> */}
          </div>
        );

      // case "image":
      // 	return (
      // 		<div className="custom-file">
      // 			<input
      // 				name="value"
      // 				type="file"
      // 				className="custom-file-input"
      // onChange={(e) => {
      // 	let duplicate = { ...city };
      // 	duplicate.image = e.currentTarget.files[0];
      // 	setCity(duplicate);
      // }}
      // 		/>
      // 		<label className="custom-file-label">Choose file</label>
      // 	</div>
      // );

      case "call_waiting_image": return (<>
        <label>Choose Image</label>
        <div className="custom-file">
          <input
            name="value"
            type="file"
            className="custom-file-input"
            name="image"
            onChange={
              (e) => uploadImage(e)
            }
          />
          <label className="custom-file-label">
            {image ? image?.name : "Choose File"}
          </label>
        </div></>
      )

      default:
        return (
          <div>
            <label>Value</label>
            {menuContent?.value &&
           <div className="react_quill_container">
           <ReactQuill 
             theme="snow"
             value={menuContent?.value}
                   onChange={(value) => {
                     let duplicate = { ...menuContent };
                     duplicate.value = value;
                     setmenuContent(duplicate);
                   }} />
              </div>
             }
            {/* <textarea
              rows="1"
              type="text"
              className="py-3 form-control "
              placeholder=""
              value={menuContent?.value}
              onChange={(e) => {
                let duplicate = { ...menuContent };
                duplicate.value = e.target.value;
                setmenuContent(duplicate);
              }}
            /> */}
          </div>
        );
    }
  };

  return (
    <Card className="p-3">
      <form
        className="py-3"
        onSubmit={(e) => {
          editMenuContentHandler(e);
        }}
      >
        <div className="form-group m-0 py-3">
          <label>Key</label>
          <input
            type="text"
            className="form-control "
            placeholder=""
            disabled
            value={menuContent?.key}
          />
        </div>
        <div className="form-group m-0 py-3">
          {inputHandler(menuContent?.key)}
        </div>
        <div className="d-flex justify-content-end py-3">
          <Button className="btn btn-primary shadow sharp px-4" type="submit">
            Submit
          </Button>
        </div>
      </form>
    </Card>
  );
};
export default EditContent;
