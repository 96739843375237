import Axios from "axios";
import { removePTag } from "./removePTag";
let API_URL = process.env.REACT_APP_BACKEND_URL;

export const PageContentByService = (city_id, datatype) => {
	return Axios.post(API_URL + `/getCityDataByType`, {city_id, datatype});
};
export const UpdateMenuByService = (values) => {
	// values.value = removePTag(values.value);
	values.value = values.title
	return Axios.post(API_URL + "/updatepage", values);
};
export const GetSingleMenuByService = (id) => {
	return Axios.post(API_URL + "/getSingleOnlyPageData", id);
};
export const GetSingleMenuContentByService = (id) => {
	return Axios.post(API_URL + "/getSinglePageContentData", id);
};
export const UpdateSingleMenuContentByService = (value) => {
	value.value = removePTag(value.value);
	return Axios.post(API_URL + "/updatepagecontent", value);
};
