import React, { useEffect, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import CityImage from "../../../images/skyline.png";
import { CityService } from "../../services/city";

const ViewCity = (props) => {
	let { history } = props;
	const [getCity, setGetCity] = useState([]);
	useEffect(() => {
		CityService()
			.then((response) => {
				setGetCity(response.data.data);
				localStorage.setItem("cityId", response.data.data[0]?.id);
				localStorage.setItem("cityName", response.data.data[0]?.name);
			})
			.catch((err) => {
				console.log("res", err);
			});
	}, []);
	const selectCityHandler = (e) => {
		localStorage.setItem("cityId", e.target.value);
		localStorage.setItem("cityName", e.target.options[e.target.selectedIndex].text);
	};
	return (
		<div className="d-flex justify-content-center align-items-center chooseCity_h_100vh">
			<div className="w-50 rounded shadow-lg d-flex">
				<div className="w-50  bg-primary d-flex justify-content-center align-items-center  py-5">
					<div>
						<img src={CityImage} alt="" height="100" />
					</div>
				</div>
				<div className="w-50 px-3  py-5">
					<form className="">
						<div>
							<h3 className="text-center">Choose City</h3>
						</div>
						<div className="form-group m-0 py-5">
							<select
								type="text"
								className="form-control "
								placeholder="Select City"
								onChange={(e) => {
									selectCityHandler(e);
								}}
								required
							>
								{getCity?.map((city) => (
									<option value={city?.id}>{city?.name}</option>
								))}
							</select>
						</div>
						<div className="d-flex justify-content-center py-3">
							<Button
								className=" btn btn-primary shadow sharp px-4 mr-2"
								type="submit"
								onClick={() => {
									history.push(`/`);
								}}
							>
								Select
							</Button>
							<Button
								className="btn btn-success shadow sharp px-4 "
								onClick={() => {
									history.push(`/add-city`);
								}}
							>
								Add City
							</Button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};
export default withRouter(ViewCity);
