import Axios from "axios";
let API_URL = process.env.REACT_APP_BACKEND_URL;

export const getAuditBuildingService = () => {
	return Axios.get(API_URL + `/auditBuildings`);
};


export const getAuditUsersService = (id) => {
    if(id)
	    return Axios.get(API_URL + `/auditUsers/${id}`);
};

export const updateUserStatus = (values) => {
	return Axios.post(API_URL + "/user/profile", values);
};

export const uploadOfferService = (value) => {
	return Axios.put(API_URL + "/auditOffer", value);
};

