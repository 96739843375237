import { Card } from "react-bootstrap";
import { Button } from "react-bootstrap";

const EditContent = () => {
	return (
		<Card className="p-3">
			<div className="d-flex justify-content-end align-items-center">
				<div className="col-2 px-0">
					<select type="text" className="form-control " placeholder="">
						<option>Select Language</option>
						<option>English</option>
						<option>Dutch</option>
					</select>
				</div>
			</div>
			<form className="py-3">
				<div className="form-group m-0 py-3">
					<label>Title</label>
					<input type="text" className="form-control " placeholder="" />
				</div>
				<div className="form-group m-0 py-3">
					<label>Description</label>
					<textarea type="text" className="form-control py-2" placeholder="" />
				</div>
				<div className="d-flex justify-content-end py-3">
					<Button className="btn btn-primary shadow sharp px-4">Submit</Button>
				</div>
			</form>
		</Card>
	);
};
export default EditContent;
