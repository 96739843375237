import React, { useCallback, useEffect, useState } from "react";
import { Card, Tab, Nav, Modal, Button } from "react-bootstrap";
import axios from "axios";
import CustomTable from "../../../components/customTable";
import ParentModal from "../../../components/modals/ParentModal";
import DetailModal from "../../../components/modals/DetailModal";
import Spinner from "react-bootstrap/Spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const tableHeader = ["#", "Title", "Image", "Action"];
const tableDetailsHeader = ["#", "Key", "Value", "Action"];

const Issues = () => {
  const [currentTab, setCurrentTab] = useState(false);
  const [state, setState] = useState({
    activeTab: "",
    basicModal: false,
    detailModal: false,
    tabData: [],
    data: [],
  });

  const getCall = (tab) => {
    axios
      .get(process.env.REACT_APP_BACKEND_URL + `support/`)

      .then((response) => {
        const data = response.data.data.Issues;
        if (tab) {
          setCurrentTab(tab);
          setState((st) => ({
            ...st,
            activeTab: tab,
            data: data,
          }));
        } else {
          setCurrentTab(data[0].title);
          setState((st) => ({
            ...st,
            activeTab: data[0].title,
            data: data,
          }));
        }
      });
  };
  useEffect(() => {
    getCall();
  }, []);
  const setRecord = useCallback((record) => {
    setState((st) => ({ ...st, record, basicModal: true }));
  }, []);
  const setRecordDetail = useCallback((record) => {
    setState((st) => ({ ...st, record, detailModal: true }));
  }, []);
  const handleInputChange = (e) => {
    setState((st) => ({
      ...st,
      record: {
        ...st.record,
        [e.target.name]: e.target.value,
      },
    }));
  };
  const filterData = state.data.filter((obj) => obj.title === state.activeTab);
  // Notification
  const notify = (status, text) => {
    if (status == "success") {
      toast.success(`✅ ${text}`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else if (status == "error") {
      toast.error(`❌ ${text} !`, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
    }
  };
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {/* Parent Modal */}
      <ParentModal
        StateData={state}
        SetState={setState}
        GetData={getCall}
        Tab="support"
        setNotification={notify}
      />
      {/* Details Modal */}
      <DetailModal
        StateData={state}
        SetState={setState}
        GetData={getCall}
        Tab="support"
        setNotification={notify}
      />
      <Card>
        <Card.Header>
          <Card.Title>{state.activeTab} Content</Card.Title>
        </Card.Header>
        <Card.Body>
          <div className="custom-tab-1">
            <Tab.Container defaultActiveKey={state.activeTab}>
              <Nav as="ul" className="nav-tabs">
                {state?.data?.map((tab, i) => (
                  <Nav.Item as="li" key={i}>
                    <Nav.Link
                      onClick={() => {
                        setState((st) => ({
                          ...st,
                          activeTab: tab.title,
                        }));
                      }}
                      eventKey={tab.title}
                    >
                      {tab.title}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
              <Tab.Content activeTab={state.activeTab} className="pt-4">
                {filterData?.map((page) => (
                  <Tab.Pane eventKey={page.title} key={page.id} tabId={page.id}>
                    <CustomTable
                      data={filterData}
                      headers={tableHeader}
                      onEdit={setRecord}
                    />
                    <Card>
                      <Card.Header>
                        <Card.Title>Details</Card.Title>
                      </Card.Header>
                      <Card.Body>
                        <CustomTable
                          headers={tableDetailsHeader}
                          onEdit={setRecordDetail}
                          detailsTable={filterData[0]["pageContent"]}
                        />
                      </Card.Body>
                    </Card>
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </Tab.Container>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};
export default Issues;
